<section class="flex h-full flex-col">
    <mat-progress-bar
        class="opacity-0"
        [class.opacity-100]="loader.isLoading()"
        mode="indeterminate"
        aria-label="loading-bar"></mat-progress-bar>
    <shrl-top-bar> </shrl-top-bar>
    <mat-sidenav-container class="h-full">
        <mat-sidenav
            class="mr-14"
            mode="push"
            [autoFocus]="false"
            [opened]="navigationService.sidenavOpened$ | async"
            (closedStart)="navigationService.sidenavOpened(false)">
            <shrl-sidenav></shrl-sidenav>
        </mat-sidenav>
        <mat-sidenav-content class="bg-shrl-slate-200" #pageContent>
            <router-outlet></router-outlet>
            <shrl-footer></shrl-footer>
        </mat-sidenav-content>
    </mat-sidenav-container>
</section>
