import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  AfterViewInit,
  ViewChild,
  inject,
} from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { SidenavComponent } from '../navigation/sidenav/sidenav.component';
import { SharelockCommonModule } from '../../../share/modules/sharelock-common.module';
import { NavigationService } from '../../services/navigation.service';
import { Observable } from 'rxjs';
import { TopBarComponent } from '../navigation/top-bar/top-bar.component';
import { FooterComponent } from '../footer/footer.component';
import { LoaderService } from '../../services/loader.service';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';

@Component({
  selector: 'shrl-frame',
  standalone: true,
  imports: [
    SharelockCommonModule,
    MatSidenavModule,
    TopBarComponent,
    FooterComponent,
    SidenavComponent,
    MatProgressBarModule,
    RouterOutlet,
  ],
  templateUrl: './frame.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FrameComponent implements AfterViewInit {
  readonly #router = inject(Router);

  readonly navigationService = inject(NavigationService);
  readonly loader = inject(LoaderService);

  isSidenavOpened = false;
  @ViewChild('pageContent', { read: ElementRef }) pageContent?: ElementRef;

  constructor() {}

  ngAfterViewInit() {
    this.#scrollOnNavigationEnd();
  }

  isSidenavOpened$ = (): Observable<boolean> =>
    this.navigationService.sidenavOpened$;

  #scrollOnNavigationEnd() {
    this.#router.events.subscribe((event) => {
      if (event instanceof NavigationEnd && this.pageContent) {
        this.pageContent.nativeElement.scrollTop = 0;
      }
    });
  }
}
