import { authGuard } from '../../core/guards/auth.guard';
import {
  ENavigationUnitComponentSelection,
  ENavigationRoute,
} from '../../core/model/navigation.model';
import { EUserRole } from '../../core/model/user.model';
import { IShrlRoutes } from '../../shrl.routes';
import { adminGuard } from '../dashboard/guards/admin.guard';

export const SUBSCRIPTIONS_ROUTES: IShrlRoutes = [
  {
    canActivate: [authGuard, adminGuard],
    path: ENavigationRoute.SUBSCRIPTIONS,
    loadComponent: () =>
      import('./pages/subscriptions.component').then(
        (x) => x.SubscriptionsComponent
      ),
    data: {
      componentSelection: [
        ENavigationUnitComponentSelection.SIDENAV,
        ENavigationUnitComponentSelection.TOPBAR,
      ],
      visibleForUsers: [EUserRole.ROLE_ADMIN],
      label: 'SUBSCRIPTIONS.HEADING',
    },
  },
];
