import { authGuard } from '../../core/guards/auth.guard';
import {
  ENavigationRoute,
  ENavigationUnitComponentSelection,
} from '../../core/model/navigation.model';
import { EUserRole } from '../../core/model/user.model';
import { IShrlRoutes } from '../../shrl.routes';
import { adminGuard } from './guards/admin.guard';
import { AdminBreadcrumbResolver } from './services/admin-api-breadcrumb.service';

export const DASHBOARD_ROUTES: IShrlRoutes = [
  {
    path: ENavigationRoute.DASHBOARD,
    data: {
      label: 'DASHBOARD.DASHBOARD.TITLE',
      breadcrumbLabel: 'DASHBOARD.DASHBOARD.TITLE',
      visibleForUsers: [EUserRole.ROLE_ADMIN],
      componentSelection: [
        ENavigationUnitComponentSelection.TOPBAR,
        ENavigationUnitComponentSelection.SIDENAV,
      ],
    },
    canActivate: [authGuard, adminGuard],
    loadComponent: () =>
      import('./pages/dashboard/dashboard.component').then(
        (x) => x.DashboardComponent
      ),
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: ENavigationRoute.USERS,
      },
      {
        path: ENavigationRoute.USERS,
        data: {
          label: 'DASHBOARD.USERS.TITLE',
          breadcrumbLabel: 'DASHBOARD.USERS.TITLE',
          visibleForUsers: [EUserRole.ROLE_ADMIN],
          componentSelection: [
            ENavigationUnitComponentSelection.TOPBAR,
            ENavigationUnitComponentSelection.SIDENAV,
          ],
        },
        children: [
          {
            path: '',
            loadComponent: () =>
              import('./pages/users-list/users-list.component').then(
                (x) => x.UsersListComponent
              ),
            data: {
              visibleForUsers: [EUserRole.ROLE_ADMIN],
            },
          },
          {
            path: ':id',
            loadComponent: () =>
              import(
                './components/user/user-container/user-container.component'
              ).then((x) => x.UserContainerComponent),
            resolve: { breadcrumbLabel: AdminBreadcrumbResolver },
            data: {
              breadcrumbLabel: 'DASHBOARD.USER.TITLE',
            },
            children: [
              {
                path: '',
                pathMatch: 'full',
                redirectTo: ENavigationRoute.USER_INFO,
              },
              {
                path: ENavigationRoute.USER_INFO,
                loadComponent: () =>
                  import('./pages/user-info/user-info.component').then(
                    (x) => x.UserInfoComponent
                  ),
                data: {
                  label: 'DASHBOARD.USER_INFO.TITLE',
                  visibleForUsers: [EUserRole.ROLE_ADMIN],
                },
              },
              {
                path: ENavigationRoute.APARTMENTS,
                loadComponent: () =>
                  import('./pages/apartments/apartments.component').then(
                    (x) => x.ApartmentsComponent
                  ),
                data: {
                  label: 'DASHBOARD.APARTMENTS.TITLE',
                  visibleForUsers: [EUserRole.ROLE_ADMIN],
                },
              },
              {
                path: ENavigationRoute.ROOMS,
                loadComponent: () =>
                  import('./components/rooms/rooms.component').then(
                    (x) => x.RoomsComponent
                  ),
                data: {
                  label: 'DASHBOARD.ROOMS.TITLE',
                  visibleForUsers: [EUserRole.ROLE_ADMIN],
                },
              },
              {
                path: ENavigationRoute.RESERVATIONS,
                loadComponent: () =>
                  import('./pages/reservations/reservations.component').then(
                    (x) => x.ReservationsComponent
                  ),
                data: {
                  label: 'DASHBOARD.RESERVATIONS.TITLE',
                  visibleForUsers: [EUserRole.ROLE_ADMIN],
                },
              },
              {
                path: ENavigationRoute.SUBSCRIPTIONS,
                loadComponent: () =>
                  import(
                    './components/subscriptions/user-subscriptions.component'
                  ).then((x) => x.UserSubscriptionsComponent),
                data: {
                  label: 'DASHBOARD.SUBSCRIPTIONS.TITLE',
                  visibleForUsers: [EUserRole.ROLE_ADMIN],
                },
              },
              {
                path: ENavigationRoute.DISCOUNTS,
                loadComponent: () =>
                  import('./pages/discounts/discounts.component').then(
                    (x) => x.DiscountsComponent
                  ),
                data: {
                  label: 'DASHBOARD.DISCOUNTS.TITLE',
                  visibleForUsers: [EUserRole.ROLE_ADMIN],
                },
              },
            ],
          },
        ],
      },
    ],
  },
];
