import { authGuard } from '../../core/guards/auth.guard';
import { userOrAdminGuard } from '../../core/guards/role/user-or-admin-role.guard';
import {
  ENavigationUnitComponentSelection,
  ENavigationRoute,
} from '../../core/model/navigation.model';
import { EUserRole } from '../../core/model/user.model';
import { IShrlRoutes } from '../../shrl.routes';

export const ORDERS_ROUTES: IShrlRoutes = [
  {
    path: ENavigationRoute.ORDERS,
    canActivate: [authGuard, userOrAdminGuard],
    data: {
      label: 'ORDERS.HEADING',
      iconName: 'shrl_orders',
      componentSelection: [ENavigationUnitComponentSelection.TOPBAR],
      visibleForUsers: [EUserRole.ROLE_USER, EUserRole.ROLE_ADMIN],
    },
    children: [
      {
        path: '',
        loadComponent: () =>
          import('./pages/orders/orders.component').then(
            (x) => x.OrdersComponent
          ),
        data: {
          componentSelection: [
            ENavigationUnitComponentSelection.SIDENAV,
            ENavigationUnitComponentSelection.TOPBAR,
          ],
          iconName: 'shrl_orders',
          visibleForUsers: [EUserRole.ROLE_USER, EUserRole.ROLE_ADMIN],
          label: 'ORDERS.LIST.HEADING',
        },
      },
      {
        path: ENavigationRoute.NEW,
        loadComponent: () =>
          import('./pages/create-order/create-order.component').then(
            (x) => x.CreateOrderComponent
          ),
        data: {
          label: 'ORDERS.CREATE.HEADING',
          componentSelection: [ENavigationUnitComponentSelection.SIDENAV],
          visibleForUsers: [EUserRole.ROLE_USER, EUserRole.ROLE_ADMIN],
        },
      },
    ],
  },
];
