import { Route } from '@angular/router';
import { ERROR_ROUTES } from './share/components/error-component/error.route';
import { ErrorComponent } from './share/components/error-component/error.component';
import { ErrorRouteData } from './share/components/error-component/error-component.model';
import { HOME_ROUTES } from './features/home/home.routes';
import { ACCOUNT_ROUTES } from './features/account/account.routes';
import { PROPERTY_ROUTES } from './features/property/property.routes';
import { GUEST_ROUTE } from './features/guest/guess.routes';
import { SUPPORT_ROUTES } from './features/support/support.routes';
import { INavigationData } from './core/model/navigation.model';
import { SUBSCRIPTIONS_ROUTES } from './features/subscriptions/subscriptions.routes';
import { DASHBOARD_ROUTES } from './features/dashboard/dashboard.routes';
import { REQUEST_ROUTES } from './features/installation/request.routes';
import { ORDERS_ROUTES } from './features/orders/orders.routes';

export interface IShrlRoute extends Route {
  data?: INavigationData;
  children?: IShrlRoutes;
}
export declare type IShrlRoutes = IShrlRoute[];

export const routes: IShrlRoutes = [
  ...ERROR_ROUTES,
  ...HOME_ROUTES,
  ...PROPERTY_ROUTES,
  ...ORDERS_ROUTES,
  ...GUEST_ROUTE,
  ...SUPPORT_ROUTES,
  ...ACCOUNT_ROUTES,
  ...DASHBOARD_ROUTES,
  ...REQUEST_ROUTES,
  ...SUBSCRIPTIONS_ROUTES,
  {
    path: '**',
    component: ErrorComponent,
    data: {
      message: 'Page not found', // to replace by json path to i18n
    } as ErrorRouteData,
  },
];
